import React, { useContext } from 'react';
import useTranslations from "../../utils/useTranslations"
import { FaApple } from 'react-icons/fa';
import Button from "../../components/button"
import Logo from "../../components/logo"
import btwebScreenshot from "../../images/downloads/btweb-windows-screen.png"
import btwebScreenshot2x from "../../images/downloads/btweb-windows-screen@2x.png"
import btclassicScreenshot from "../../images/downloads/btclassic-mac-screen.jpg"
import btclassicScreenshot2x from "../../images/downloads/btclassic-mac-screen@2x.jpg"
import btclassicScreenshot3x from "../../images/downloads/btclassic-mac-screen@3x.jpg"

import arrowBlack from "../../images/icons/arrow-black.svg"
import LocalizedLink from "../../components/localizedLink"
import warning from "../../images/icons/warning.svg"
import { CompatibilityContext } from "../../components/compatibilityContext";


const PlatformsMac = ({ pageContext: {text, locale} }) => {
const t = useTranslations(text)
  
  	const compatibilityHandler = useContext(CompatibilityContext)
	function handleClick(e, action, product, link){
		compatibilityHandler(e, action, product, ["Osx"], link)
	}

	const webLink = "/products/mac/bittorrent-web-compare/"
	const classicLink = "/downloads/complete/classic"

  	return (
		<div className="container my-5 pt-5">
			<div className="row mb-5">
				<div className="col-12 text-center text-md-left">
					<h1><FaApple className="mb-2" />  <span className="ml-2 d-inline-block">{t("BitTorrent Products for Mac")}</span></h1>
				</div>
			</div>

			<p className="text-size-32 font-weight-bold">
				{t(`Free Torrent Clients for Mac`)}
			</p>
			<div className="row">
				<div className="col-12 text-center text-md-left">
					<p className="platforms-section-description">
						{t(`We offer the most trusted BitTorrent Mac torrent clients. Download our easy-to-use web torrent client that supports macOS Catalina and below, or our bulk torrent desktop app if you’re running macOS Mojave 10.14 and below.`)}
					</p>
				</div>
			</div>

			<div className="row mt-5">
				<div className="col-md-7 order-2 order-md-1 text-center text-md-left">


					<div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
		              	<Logo color="black" tag="h2" productName="Web" className="text-center text-md-left mb-5 mb-md-0" fsMax={22}/>
					</div>

					<p className="platforms-section-description mt-0 mt-md-4">
						{t(`Now available for macOS Catalina, BitTorrent Web for Mac is a simplified torrent client that installs directly into your favorite browser. Download any file type to store locally in the folder of your choice and play torrent files while they download without needing to search folders or look for other media player apps.`)}
					</p>
					<p className="mt-3"> * Having trouble installing on Mac? Find the solution <a href="/en/support/solutions/articles/29000034450--will-damage-your-computer-you-should-move-it-to-the-trash-" target="_blank" rel="noopener noreferrer" className="text-primary">here</a>.</p>
					<Button href={webLink} id="mac-platforms-webfree" className="btn-primary button-filled text-spacing-1">{t("Free Download")}</Button>
					<div className="d-block d-md-inline-block mt-4 mt-md-0">
						<LocalizedLink
							id="platforms-mac-btweb-learnmore"
						className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more ml-3" 
						to="/products/mac/bittorrent-web-free">

						{t(`Learn more`)} 

						</LocalizedLink>
						<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
					</div>
					

				</div>
				<div className="col-md-5 order-1 order-md-2 mb-4 mb-md-0 text-center text-md-right">
					<img src={btwebScreenshot} srcSet={`${btwebScreenshot2x} 2x`} alt="BitTorrent Web Screenshot" className="img-fluid w-100"></img>
	                <div style={{fontSize: '10px'}} className="mt-2 text-center"><a id="platforms-mac-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>
					
				</div>
				<div className="col-12 order-3 mt-5">
					<hr className="platforms-hr-border"/>
				</div>
			</div>

			<div className="row pb-5 my-5">
				<div className="col-md-7 order-2 order-md-1 text-center text-md-left">

					<div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
		              	<Logo color="black" tag="h2" productName="Classic" className="text-center text-md-left mb-5 mb-md-0" fsMax={22}/>
					</div>

					<p className="platforms-section-description mt-0 mt-md-4">
						{t(`Available for MacOS Mojave 10.14 and below, BitTorrent Classic for Mac is the original Mac torrent downloader for experienced users. Ideal for fast, bulk downloading from the desktop, this BitTorrent desktop app includes advanced customization options for those running older macOS versions.`)}
					</p>
					<Button onClick={(e) => {handleClick(e, "downloading", "Classic", classicLink)}} href={classicLink} id="mac-platforms-classicfree" className="btn-primary button-filled text-spacing-1">{t("Free Download")}</Button>
					<div className="d-block mt-3">
						<img src={warning} alt="Warning Icon" style={{ height: `20px` }} className="warning-icon mr-1 d-inline pb-1"/>
	                    <p className="d-inline text-left"> {t(`Not compatible with MAC OS Catalina Version 10.15 and above`)}</p>
                    </div>
				</div>
				<div className="col-md-5 order-1 order-md-2 mb-4 mb-md-0 text-center text-md-right">
					<img src={btclassicScreenshot} srcSet={`${btclassicScreenshot2x} 2x, ${btclassicScreenshot3x} 3x`} alt="BitTorrent Classic Screenshot" className="img-fluid w-100"></img>
				</div>
			</div>
			
		</div>
 	)
}

export default PlatformsMac